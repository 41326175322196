import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CrossIcon from "assets/images/cross-large.svg";
import CONFIG from "config";
import { Link } from "react-router-dom";
import authService from 'redux/auth';
import { getUserId } from "utils/auth/auth";
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getShareViaEmailAPIData } from 'redux/actions/shareViaEmail/shareViaEmail.actions';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "./CustomTooltip.scss";

export const CustomTooltip = ({ open, selectedApprover, handleClosePopup, templateDetails, selectedCheckBoxes, data, isTemplate, handleCancel }) => {
    const dispatch = useDispatch();
    const filteredTemplateData = !isTemplate ? data?.filter(item => selectedCheckBoxes?.includes(item?.templateId)) : templateDetails;
    const approverMainInputRef = useRef();
    const mailTooltipRef = useRef();
    const [templateData, setTemplateData] = useState(filteredTemplateData);
    const [accessToken, setAccessToken] = useState([]);
    const [selectedMainApprover, setSelectedMainApprover] = useState(selectedApprover);
    const [selectedApprovername, setSelectedApprovername] = useState('');
    const [selectedApproverHrid, setSelectedApproverHrid] = useState('');
    const [selectedApproverDesignation, setSelectedApproverDesignation] = useState('');
    const [getPeopleData, setPeopleData] = useState({});
    const [openModel, setOpenModel] = useState(open);

    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = async () => {
        const hrid = await getUserId();
        const resp = await getUserProfilePictures([hrid]);
        console.log('resp from people api', resp);
        if (resp?.length > 0) {
            setPeopleData({
                "firstName": resp[0]?.firstName,
                "lastName": resp[0]?.lastName,
                "businessTitle": resp[0]?.businessTitle,
                "hostOfficeCity": resp[0]?.hostOfficeLocation?.city,
                "hostOfficeCountry": resp[0]?.hostOfficeLocation?.country,
                "primaryWorkPhone": resp[0]?.phones?.phoneNumber || ''
            });
        }
    }

    useEffect(() => {
        setAccessToken(authService.getAccessToken());
    }, []);

    useEffect(() => {
        handleClosePopup(openModel);
    }, [openModel]);

    useEffect(() => {
        mailTooltipRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [mailTooltipRef]);

    // useEffect(() => {
    //     const handleMouseDown = (event) => {
    //         if (mailTooltipRef.current && !mailTooltipRef.current.contains(event.target)) {
    //             setOpenModel(false);
    //         }
    //     };
    //     document.addEventListener("mousedown", handleMouseDown);

    //     return () => {
    //         document.removeEventListener("mousedown", handleMouseDown);
    //     };
    // }, [open]);

    useEffect(() => {
        let timeout;
        function updateApproverSelection(e) {
            if (e.detail && e.detail.length > 0) {
                setSelectedMainApprover(e.detail[0].email);
                setSelectedApprovername(e?.detail[0]?.firstName + " " + e?.detail[0]?.lastName);
                setSelectedApproverHrid(e?.detail[0]?.id);
                setSelectedApproverDesignation(e?.detail[0]?.title)
            } else {
                setSelectedMainApprover('');
                setSelectedApprovername('');
                setSelectedApproverHrid('');
                setSelectedApproverDesignation('');
            }
        }
        if (approverMainInputRef && approverMainInputRef.current) {
            const shadowRoot = approverMainInputRef.current.shadowRoot;
            timeout = setTimeout(() => {
                const element = shadowRoot.querySelector('.selection-list');
                if (element) {
                    element.style.background = 'var(--Fills-light, #FFF)';
                }
            }, 800);

            approverMainInputRef.current.addEventListener("selectedPeopleChanged", updateApproverSelection, false);
        }

        return () => clearTimeout(timeout);
    }, [openModel]);

    const handleShareApproval = (name, email, hrid, designation) => {
        let payload = {
            "requestId": isTemplate ? templateData?.requestId : templateData[0]?.requestId,
            "approverName": name,
            "approverEmail": email,
            "approvedHrId": hrid,
            "approverDesignation": designation,
            "templateId":
                isTemplate ? [templateData?.templateId] : templateData.map(item => item.templateId),
            "requestor": getPeopleData
        }
        console.log('payload>>', payload);
        dispatch(getShareViaEmailAPIData(payload));
        setOpenModel(!openModel);
    }

    return (
        <div className='approver-selection-popup' ref={mailTooltipRef}>
            <div className='approver__field'>
                Select approver to share via email for approval
                <img className="close-icon" src={CrossIcon} alt="close"
                    onClick={() => setOpenModel(!openModel)} />
                <div className='approver-input'>
                    <bcg-people-search app-name={CONFIG.applicationName} id="templateApprover" name="templateApprover" limit="20" width="100%" is-show-profile="false" is-multi-select="false"
                        ref={approverMainInputRef} api-key={CONFIG.PEOPLE_API_X_API_KEY} access-token={accessToken} environment='production-internal' />
                </div>
            </div>
            <div className='tooltip-btn-div'>

                {selectedMainApprover ?
                    <div className='mail-button'
                        onClick={(e) => {
                            // window.location.href = `mailto:${selectedMainApprover}?subject=Approval%20Request`;
                            // e.preventDefault();
                            handleShareApproval(selectedApprovername, selectedMainApprover, selectedApproverHrid, selectedApproverDesignation);
                        }}>
                        <div className='approver-mail-btn'><span>Share via Email for Approval</span></div>
                    </div> :
                    <div className='approver-mail-btn'>
                        <span>Share via Email for Approval</span>
                    </div>
                }

            </div>
        </div>
    );
};
